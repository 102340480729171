.scrollButton {
  background-color: #F4EBFF;
  border-radius: 22.5px;
  bottom: 70px;
  box-shadow: 0px 4px 4px rgba(25, 21, 89, 0.2);
  color: #fff;
  cursor: pointer;
  font-size: 35px;
  height: 45px;
  line-height: 40px;
  margin-left: -22.5px;
  position: fixed;
  right: 10px;
  text-align: center;
  width: 45px;
  z-index: 45;
}

.scrollButton:hover {
  color: #fff;
}

.scrollButton::before,
.scrollButton::after {
  animation: ripple 3.0s ease-out infinite;
  border-radius: 50%;
  border: #F4EBFF 12px solid;
  bottom: 0px;
  content: "";
  height: 45px;
  position: absolute;
  right: 0px;
  opacity: 0.3;
  width: 45px;
  z-index: -1;
}

.scrollButton::after {
  animation-delay: -0.6s;
}

.scrollButton svg {
  padding-bottom: 5px;
  width: 22px;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }

  50%, 100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
