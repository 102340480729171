.wrapper {
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 15px 1fr 100px;
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
}

.wrapper.hideGoBack {
  cursor: default;
  grid-template-columns: 1fr 100px;
}

.flightNumber {
  color: #232323;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 100;
}

.secondaryInfo {
  color: #808080;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  margin-bottom: 3px;
  position: relative;
  z-index: 100;
}

.primaryInfo {
  color: #232323;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 100;
}

.transportImg {
  height: 100%;
  /* position: absolute; */
  right: 20px;
  width: 100px;
}

.transportImgLogo {
  height: 100px;
  /* position: absolute; */
  right: 20px;
  object-fit: scale-down;
  width: 150px;
}

.transportImgLogo img {
  height: 100px;
  object-fit: scale-down;
  width: 150px;
}

.transportLogo {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100px;
  position: absolute;
  right: 20px;
  width: 150px;
  z-index: 1000;
}

.transportLogoImg {
  height: 100%;
  max-height: 70px;
  object-fit: scale-down;
  width: 100%;
}

.transportLogoImg.noBtn {
  max-height: 100px;
}

.transportLogo button {
  font-size: 12px;
  height: 30px;
  line-height: 10px;
  width: 90px;
}

@media screen and (min-width: 100px) and (max-width: 767px)  {
  .transportImg,
  .transportImgLogo {
    display: none;
  }
}
