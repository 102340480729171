.wrapper {
  background-color: var(--app-background-color);
  background-image: url('./../../../img/widget/mainPageBg.webp');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 20px;
}

.wrapper h1 {
  font-size: 30px;
  font-weight: 700;
  white-space: pre-wrap;
}

.wrapper h1 span {
  font-weight: 400;
}

.wrapper h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.wrapper ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0px;
}

.wrapper li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 140px;
}

.wrapper li svg {
  margin-bottom: 12px;
}

.logo {
  color: rgba(5, 109, 250, 0.45);
  font-size: 40px;
  font-weight: 500;
}

.content {
  align-self: center;
}

.poweredBy {
  align-items: self-start;
  align-self: end;
  color: black;
  font-size: 14px;
  display: flex;
  justify-self: end;
}

.poweredBy:hover {
  color: black;
}

.poweredBy img {
  margin-left: 8px;
  width: 100px;
}
