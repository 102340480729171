.wrapper {
  margin-bottom: 37px;
}

.titleWrapper {
  align-items: center;
  display: flex;
  margin-bottom: 7px;
}

.titleWrapper h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: left;
}

.contentWrapper {
  align-items: center;
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 350px;
}

.welcomeBlockWrapper {
  cursor: default !important;
  display: flex;
  flex-direction: column;
}

.searchCodeIconWrapper {
  align-items: center;
  background: #0068FF;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  padding: 2px;
  width: 35px;
}

.searchSpin {
  margin-bottom: -7px;
}

.searchSpin span i {
  background-color: white;
}

.search {
  background: #E9E8FF;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(25, 21, 89, 0.12);
}

.search input {
  background: #E9E8FF;
}

.welcomeBlock {
  background: #E9E8FF;
  box-shadow: 0px 0px 4px rgba(25, 21, 89, 0.12);
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  padding: 50px;
  position: relative;
  overflow: hidden;
}

.welcomeBlock .bgDecoration {
  right: 0px;
  position: absolute;
  top: 0px;
}

.welcomeBlock .title {
  font-size: 32px;
  font-weight: 600;
  position: relative;
}

.welcomeBlock .description {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  line-height: 140%;  
  position: relative;
}

.welcomeBlock button {
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 30px;
  position: relative;
}

.welcomeBlock .calendarImg {
  justify-self: flex-end;
  position: relative;
}

.calendarHeader {
  align-items: center;
  color: var(--app-dark-color);
  display: flex;
  font-size: 24px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
}

.calendarHeader svg {
  cursor: pointer;
}



@media screen and (min-width: 768px) and (max-width: 1112px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
  
  .calendar {
    display: none;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }

  .welcomeBlock {
    padding: 20px 10px;
    text-align: center;
  }

  .welcomeBlock .calendarImg {
    justify-self: center;
    position: relative;
    width: 90%;
  }

  .welcomeBlock button {
    grid-column: 1 / 3;
  }

  .calendar {
    display: none;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }

  .welcomeBlock {
    grid-template-columns: 1fr;
    padding: 20px 10px;
    text-align: center;
  }

  .welcomeBlock .calendarImg {
    justify-self: center;
    position: relative;
    width: 90%;
  }

  .calendar {
    display: none;
  }
}
