.filtersBtnSmallScreen,
.smallScreenFilters {
  display: none;
}

.filterBtn {
  align-items: center;
  background-color: white;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
}

.filterBtn.disabled {
  cursor: default;
}

.filterBtn svg {
  margin-right: 30px;
}

.filterBtn svg path {
  fill: white;
  stroke: var(--app-primary-color);
}

.filterBtn.active {
  background-color: var(--app-primary-color);
  color: #FFFFFF;
}

.filterBtn.active svg path {
  fill: var(--app-primary-color);
  stroke: white;
}

.filtersTitle {
  border-bottom: 2px solid rgba(0, 104, 255, 0.12);
  font-size: 20px;
  font-weight: 900;
  padding: 15px;
  text-align: center;
}

.filtersFooter {
  border-top: 2px solid rgba(0, 104, 255, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
}

.filtersFooter button {
  height: 30px;
  padding: 0px;
  width: 100px;
}

.filtersFooter button:first-of-type span {
  text-decoration: underline;
}

.filterCategoryTitle {
  font-size: 17px;
  font-weight: 700;
  margin: 10px 0px;
  text-align: center;
}

.filterSubCategory {
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
  margin: 20px 30px;
  padding-bottom: 20px;
}

.filterSubCategory label {
  margin-left: 0px !important;
  margin-bottom: 10px;
}

.filterSubCategoryTitle {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.doubleInput {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}

.infoIcon {
  cursor: pointer;
  margin-left: 6px;
  width: 15px;
}

.infoIcon path {
  stroke: gray
}

.infoIcon:hover path {
  stroke: var(--app-primary-color)
}

.smallScreens {
  background-color: transparent !important;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .filtersBtn {
    display: none;
  }

  .filtersBtnSmallScreen {
    display: flex;
    justify-content: center;
    align-items:center;
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 78px;
    margin-bottom: 10px;
  }

  .filterBtn {
    display: flex;
    height: 46px;
    justify-content: center;
    width: 100%;
  }

  .filterBtn svg {
    margin-right: 0PX;
  }

  .smallScreenFilters {
    display: block;
  }

  .filterSubCategory {
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
    margin: 20px 10px;
    padding-bottom: 10px;
  }
}
