.wrapper {
  background-color: var(--app-background-color);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: calc(100vh - var(--footer-height));
}

.header {
  padding: 0px var(--app-padding-left-right);
}

.entry {
  display: flex;
  flex-direction: column;
}

.date {
  color: var(--app-primary-color);
  font-size: 16px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 12px;
  text-align: center;
}

.entry h1 {
  color: #101828;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  margin: 0px 0px 64px 0px;
}

.cover {
  height: 450px;
  margin: 0 auto;
  margin-bottom: 40px;
  object-fit: contain;
  width: 70%;
}

.contentWrapper {
  max-width: 720px;
  margin: 0 auto;
  width: 100%;
}

.carouselImg {
  height: 300px;
  object-fit: scale-down;
}

.carouselDots li button {
  background: var(--app-primary-color) !important;
  opacity: 1 !important;
}

.shareBlock {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 65px;
  max-width: 720px;
  width: 100%;
}

.socialOption {
  align-items: center;
  background: var(--White, #FFF);
  border: 1px solid var(--gray-300, #D0D5DD);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  padding: 10px;
}

.copyLink {
  align-items: center;
  background: var(--White, #FFF);
  border: 1px solid var(--gray-300, #D0D5DD);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: flex;
  padding-right: 10px;
  width: fit-content;
}
