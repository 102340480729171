

.modalTitle {
  border-bottom: 2px solid rgba(0, 104, 255, 0.12);
  font-size: 20px;
  font-weight: 700;
  padding: 12px;
  text-align: center;
}

.modalAdditionalTitle {
  font-size: 24px;
  font-weight: 600;
  margin-left: 30px;
  margin-top: 15px;
}

.formArea {
  font-size: 14px;
  padding: 0px 30px 30px 30px;
}

.fieldLabel {
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;
}

.doubleInputField {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.buttonsWrapper {
  align-items: center;
  border-top: 2px solid rgba(0, 104, 255, 0.12);
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 15px 30px 15px 30px;
}

.buttonsWrapper button {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  line-height: 12px;
  width: 97px;
}

.clearAll {
  cursor: pointer;
  font-size: 14px;
  text-decoration-line: underline;
}
