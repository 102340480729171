.wrapper {
  background-color: var(--app-background-color);
  overflow: hidden;
  width: 100vw;
}

.welcomeBlock {
  background-color: var(--app-background-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: var(--app-min-height);
  padding: 0px 60px;
  position: relative;
}

.welcomeBlock h1 {
  font-size: 70px;
  font-weight: 500;
}

.animation svg {
  position: absolute;
  top: -40px;
  right: 0px;
  width: 55%;
  z-index: 1;
}

.welcomeBlock header,
.welcomeBlock h1,
.subtitle,
.appNavigationBtnWrapper {
  position: relative;
  z-index: 100;
}

.subtitle {
  font-size: 32px;
  margin-bottom: 7px;
  text-align: center;
  white-space: pre-wrap;
}

.appNavigationBtnWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2vh 0px;
}

.appNavigationBtnWrapper button {
  font-size: 20px;
  font-weight: 600;
  width: 200px;
}

.appNavigationBtnWrapper a:last-of-type button {
  background-color: #FFA100;
  border: 1px solid #FFA100;
  margin-left: 20px;
}

.globeImgWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 20px;
  overflow: hidden;
}

.globe {
  height: 100%;
  object-fit: contain;
}

.howToUseBlock {
  background-color: white;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  padding-bottom: 30px;
}

.howToUseBlock .additionalTitle {
  color: var(--app-primary-color);
  font-size: 16px;
  font-weight: 600;
  padding-top: 40px;
  text-align: center;
}

.howToUseBlock h2 {
  font-size: 36px;
  justify-content: center;
  margin-bottom: 50px;
  text-align: center;
}

.step {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 50px 0px 10px 0px;  
}

.step.leftImage .contentArea {
  margin-right: 30px;
}

.step.rightImage img {
  justify-self: self-end;
  order: 2;
}

.step img {
  width: 100%;
}

.contentArea {
  align-self: center;
  column-gap: 15px;
  display: grid;
  /* grid-template-columns: 20px 1fr; */
  grid-template-columns: 1fr;
  padding: 0px 100px;
  position: relative;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 20px;
}

.description {
  color: #667085;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  white-space: pre-wrap;
}

.howToUseOption {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 30px;
  width: 500px;
}

.howToUseOption div {
  align-items: center;
  display: flex;
}

.howToUseOptionDivider {
  width: 500px;
}

.plusMinusIcon {
  border: 1px solid var(--app-primary-color);
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  margin-right: 10px;
  padding: 5px;
  width: 20px;
}

.plusMinusIcon path {
  stroke: var(--app-primary-color);
  stroke-opacity: 1;
}

.betaModalImg {
  align-self: center;
  margin-bottom: 40px;
  width: 190px;
}

.betaModalTitle {
  color: #101828;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}

.betaModalDescription p {
  font-size: 16px;
  text-align: center;
}

.betaModalStartBtn {
  align-items: center;
  display: flex;
  justify-content: center;
}

.betaModalStartBtn svg {
  margin-left: 7px;
}

@media screen and (min-width: 768px) and (max-width: 1424px) {
  .subtitle {
    font-size: 26px;
  }

  .step {
    column-gap: 20px;
    padding: 50px 0px 10px 60px;  
  }

  .step img {
    object-fit: cover;
    width: 100%;
  }
  
  .step.rightImage {
    margin-right: 100px;
  }

  .contentArea {
    padding: 0px 10px;
  }

  .title {
    font-size: 25px;
  }

  .description {
    font-size: 15px;
    font-weight: 400;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .subtitle {
    font-size: 20px;
  }

  .step {
    padding: 0px 30px 50px 30px;  
  }
}

@media screen and (min-width: 100px) and (max-width: 540px) {
  .howToUseOption,
  .howToUseOptionDivider {
    width: 300px;
  } 
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  .subtitle {
    font-size: 16px;
  }

  .step {
    padding: 0px 0px 50px 0px;  
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .welcomeBlock {
    padding: 0px 10px;
  }

  .animation svg {
    position: absolute;
    top: -30%;
    right: 0px;
    width: 55%;
    z-index: 1;
  }

  .appNavigationBtnWrapper button {
    font-size: 18px;
    width: 145px;
  }

  .globeImgWrapper {
    padding: 0px -10px;
  }

  .step {
    display: grid;
    grid-template-columns: 1fr;
  }

  .step.rightImage {
    grid-template-columns: 1fr;
    margin-right: 0px;
  }
  
  .step.leftImage .contentArea {
    justify-self: center;
    margin-right: 10px;
    order: -1;
  }

  .contentArea {
    padding: 10px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 22px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
  }

  .betaModalImg {
    width: 90px;
  }
}
