.listTitle {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: 0.01em;
  line-height: 25px;
  margin: 12px 0px 6px 0px;
}

.advancedFilterBtn {
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--active, #191559);
  cursor: pointer;
  display: flex;
  padding: 2px 6px;
}

.advancedFilterBtn svg {
  height: 20px;
  margin-right: 7px;
  width: 20px;
}

.advancedFilterBtn svg path {
  fill: white;
  stroke: #6F7277;
}

.warningModal {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.warningModal svg {
  margin-bottom: 30px;
}

.warningModalTitle {
  color: var(--gray-900, #101828);
  line-height: 28px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.warningModalText {
  color: rgba(0, 0, 0, 0.90);
  font-size: 16px;
  text-align: center;
}

.warningModal button {
  margin-top: 30px;
  width: 100%;
}
