.wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 24px 24px 32px 24px;
  position: relative;
}

.cover {
  height: 240px;
  object-fit: cover;
  margin-bottom: 12px;
}

.date {
  color: var(--app-primary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
}

.title {
  color: #101828;
  column-gap: 10px;
  display: grid;
  font-size: 24px;
  font-weight: 600;
  grid-template-columns: 1fr 30px;
  margin-bottom: 12px;
}

.title:hover {
  color: #101828;
}

.textPreview {
  color: #667085;
  font-size: 16px;
  height: 90px;
  margin-bottom: 12px;
}

.entryLink {
  align-items: center;
  bottom: 15px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
}

.entryLink svg {
  margin-left: 10px;
}

.entryLink svg path {
  stroke: var(--app-primary-color);
}
