.dotsMenuItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
}

.dotsMenuItem:hover {
  background: #F9F8FE;
}

.dotsMenuItem svg {
  margin-right: 15px;
}
