.linkWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 270px 50px;
  margin: 5px 0px 25px 0px;
  width: 300px;
}

.link {
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  max-height: 100px;
  padding: 10px 15px;
  overflow: auto;
  user-select: none;
  width: 270px;
}

.copyIcon {
  cursor: pointer;
}

.copyIcon:hover g path {
  stroke: var(--app-primary-color);
}

.socialShareBlock {
  display: flex;
  flex-direction: column;
}

.socialOptions {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  width: 320px;
}

.socialOption {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.socialOption:not:last-of-type {
  margin-left: 50px;
}
