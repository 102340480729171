.collapseBtn {
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-top: 14px;
  padding: 5px 10px 5px 10px;
}

.collapseBtn:first-child {
  width: 100% !important;
}

.collapseBtnContent,
.collapseBtnText {
  align-items: center;
  display: flex;
  width: 100%;
}

.filtersBtn {
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 5px 10px;
}

.filtersBtn svg {
  margin-right: 5px;
}
