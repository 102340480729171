.wrapper {
  background: #FAFBFC;
  border-radius: 4px;
  height: 300px;
  padding: 15px 12px;
  width: 330px;
}

.searchHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.searchAdditionalInfo {
  color: #57BEC0;
  font-size: 14px;
  font-weight: 600;
  height: 120px;
  overflow: auto;
}

.title {
  color: #101828;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.searchLink {
  align-items: center;
  display: flex;
  font-size: 16px;
}

.searchLink a:hover {
  color: var(--app-dark-color);
}

.copyIcon {
  cursor: pointer;
}

.copyIcon:hover g path {
  stroke: var(--app-dark-color);
}

.searchTypeTag {
  background: #F9F5FF;
  border-radius: 16px;
  color: #57BEC0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  padding: 2px 10px;
  text-align: center;
  width: fit-content;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    width: 280px;
  }
}
