.wrapper {
  background: #FFFFFF;
  border: 1px solid #CBD4E6;
  border-radius: 12px;
  cursor: pointer;
  padding: 12px;
  margin-bottom: 20px;
}

.wrapper.cruiseDetails:hover  {
  cursor: default;
  box-shadow: none;
}

.wrapper:hover {
  box-shadow: 0px 0px 4px rgba(25, 21, 89, 0.25);
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; 
}

.cardTitle {
  align-items: center;
  color: #232323;
  display: grid;
  grid-template-columns: 1fr 150px 75px;
  font-size: 18px;
  font-weight: 700;
  /* justify-content: space-between; */
  line-height: 25px;
}

.nights {
  align-items: center;
  background: #57BEC0;
  border-radius: 100px;
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: fit-content;
  justify-content: center;
  line-height: 14px;
  max-width: 70px;
  padding: 3px 6px;
}

.infoWrapper {
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
}

.tripTime {
  color: #808080;
  font-size: 12px;
  line-height: 14px;
  margin-top: -10px;
  text-align: center;
}

.additionalInfo {
  color: #161B25;
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0px;
}

.additionalInfo span {
  color: #808080;
  margin-right: 5px;
}

.additionalInfo svg path {
  fill: var(--app-dark-color);
}

.secondaryInfo {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.locationDataWrapper .additionalInfo {
  color: #808080;
}

.flightStopInfo {
  font-size: 14px;
}

.flightSummaryInfo {
  font-size: 16px;
  line-height: 22px;
  margin-top: -3px;
}

.flightDate {
  color: #232323;
  font-size: 12px;
  line-height: 16px;
}

.linerInfo {
  border: 1px solid rgba(25, 21, 89, 0.5);
  border-radius: 12px;
  color: var(--app-dark-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 19px;
  margin-bottom: 8px;
  padding: 5px 8px;
  width: fit-content;
}

.linkToFullResults {
  background: #D4E2FE;
  border: 1px solid #D4E2FE;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #0068FF;
  display: block;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
}

.saveEditBtn {
  color: #0068FF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: -20px;
  margin-bottom: 15px;
  text-align: right;
  user-select: none;
}

@media screen and (min-width: 768px) and (max-width: 860px) {
  .cardTitle {
    align-items: center;
    color: #232323;
    display: grid;
    grid-template-columns: 1fr;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .cardTitle {
    grid-template-columns: 1fr;
  }

  .nights {
    margin: 6px 0px;
  }

  .secondaryInfo {
    grid-template-columns: 1fr;
  }

  .secondaryInfo div:last-child {
    margin-top: 6px;
  }
}
