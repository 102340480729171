
.services {
  align-items: center;
  background-color: #F9F8FE;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  padding: 10px 0px;
  position: sticky;
  overflow: auto;
  top: 0px;
  z-index: 5;
}

.services::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 2px;
}

.services::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.service {
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #C2C2C2;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  user-select: none;
  width: 100px;
}

.service {
  flex: none;
  padding: 0px 5px;
  margin-bottom: 10px;
  width: auto;
}

.service.selected {
  border: 2px solid var(--app-highlight-color);
  color: var(--app-highlight-color);
}

.service:not(:last-of-type) {
  margin-right: 5px;
}
