.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  padding: 0px 60px 60px 60px;
}

.contentWrapper h1 {
  color: var(--app-dark-color);
  font-size: 36px;
  font-weight: 700;
  margin: 60px 0px;
}

.contentWrapper h2 {
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.infoBlock {
  background: #F9F8FE;
  border-radius: 8px;
  color: rgba(19, 19, 19, 0.8);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 140%;
  padding: 15px;
  text-align: center;
}

.infoBlock h2 {
  align-self: center;
}

.explanationBlock {
  column-gap: 90px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 60px;
}

.explanationBlock h2 {
  text-align: start;
}

.explanationBlock.leftImage .explanationText {
  margin-right: 60px;
}

.explanationBlock.leftImage {
  margin-left: -60px;
}

.explanationBlock.rightImage img {
  justify-self: self-end;
  order: 2;
}

.explanationBlock img {
  width: 100%;
}

.description {
  color: rgba(19, 19, 19, 0.8);
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 30px;
}

.point {
  color: #131313;
  column-gap: 5px;
  display: grid;
  font-size: 18px;
  grid-template-columns: 28px 1fr;
  line-height: 28px;
  margin: 20px;
}

.pricingBlock {
  margin-top: 60px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    padding: 0px 15px 30px 15px;
  }

  .contentWrapper h1 {
    font-size: 32px;
    margin: 25px 0px;
  }

  .contentWrapper h2 {
    font-size: 28px;
    line-height: 120%;
  }
  
  .explanationBlock {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 25px;
  }

  .explanationBlock.rightImage {
    grid-template-columns: 1fr;
    margin-right: 0px;
  }

  .explanationBlock.leftImage {
    margin-left: 0px;
  }
  
  .explanationBlock.leftImage .explanationText {
    justify-self: center;
    margin-right: 10px;
    order: -1;
  }

  .explanationText {
    padding: 10px;
    margin-bottom: 20px;
  }

  .point {
    grid-template-columns: 28px 1fr;
    line-height: 28px;
    margin: 12px 0px 0px 12px;
  }

  .pricingBlock {
    margin-top: 25px;
  }
}
