.blockWrapper,
.resultList {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 25px 5px 25px 10px;
  margin-top: 14px;
}

.resultList {
  padding-top: 0px;
}

.emptyResultList {
  background: transparent;
}

.goBackBtn {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
}

.goBackBtn svg {
  margin-right: 7px;
}
