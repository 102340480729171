.selectedLocationItem {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 290px;
}

.removeLocation {
  cursor: pointer;
  color: #B42318;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
