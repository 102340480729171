.externalLinks img {
  margin-right: 12px;
  width: 44px;
  height: 44px;
}

.linkBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkBlock + .linkBlock {
  margin-top: 16px;
}

.linkBlockText {
  width: 100%;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin: 0;
}

.linkOptionsWrapper {
  align-items: center;
  display: flex;
}

.linkBlockBtn {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  color: #0068FF;
  font-weight: 700;
  margin: 0;
  min-width: 98px;
  transition: all .3s
}

.linkBlockBtn:hover {
  text-decoration: underline;
}

.bookingWidgetIcon {
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
}

.bookingWidgetContainer {
  /* height: 85vh !important; */
  margin: 0 auto !important;
  position: relative;
  width: 90% !important;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .linkOptionsWrapper {
    flex-direction: column;
    justify-content: center;
  }

  .bookingWidgetContainer {
    width: 100% !important;
  }
}
