.wrapper {
  background-color: var(--app-background-color);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0px 60px 150px 60px;
}

.wrapper h1 {
  color: var(--app-dark-color);
  font-size: 44px;
}

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.description {
  color: var(--app-dark-color);
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
}

.searchOptions {
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(115px, 355px));
  justify-content: center;
  row-gap: 20px;
}

.option {
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
}

.optionContent {
  padding: 24px;
}

.optionContent button {
  width: 166px;
}

.optionDescription {
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
  height: 40px;
}

@media screen and (min-width: 768px) and (max-width: 1023px)  {
  .wrapper {
    height: auto;
    padding: 0px 30px 50px 30px;
  }

  .wrapper h1 {
    font-size: 32px;
  }

  .description {
    font-size: 20px;
  }
    
  .searchOptions {
    grid-template-columns: 1fr 1fr;
  }

  .option {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }

  .optionContent {
    padding: 24px 0px;
  }

  .optionContent button {
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px)  {
  .wrapper {
    height: auto;
    padding: 0px 10px 50px 10px;
  }

  .wrapper h1 {
    font-size: 32px;
  }

  .description {
    font-size: 20px;
  }
    
  .searchOptions {
    grid-template-columns: 1fr;
  }

  .option {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    width: 80%;
  }

  .optionContent {
    padding: 24px 0px;
  }

  .optionContent button {
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  .wrapper {
    height: auto;
    padding: 0px 10px 50px 10px;
  }

  .wrapper h1 {
    font-size: 24px;
  }

  .description {
    font-size: 16px;
  }

  .searchOptions {
    grid-template-columns: 1fr;
  }

  .option {
    max-width: 100%;
    text-align: center;
    width: 100%;
  }

  .optionContent {
    padding: 24px 0px;
  }

  .optionContent button {
    width: 100%;
  }
}
