.wrapper {
  background-color: var(--app-background-color);
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  height: 100vh;
  min-height: var(--app-min-height);
  overflow: hidden;
}

.wrapper header {
  padding: 0px var(--app-padding-left-right);
}

.decorationImg {
  justify-self: end;
  min-height: 100vh;
  width: 100%;
}

.decorationImgSmallScreen {
  display: none;
}

.formTitle {
  font-size: 44px;
  font-weight: 600;
  text-align: center;
}

.explanation {
  font-size: 14px;
  line-height: 120%;
  margin: 20px 0px 20px 0px;
  text-align: center;
}

.form,
.contentWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--header-height));
  justify-content: center;
  margin: 0 auto;
  min-width: 350px;
  width: 60%;
}

.label {
  font-size: 16px;
}

.forgotPasswordWrapper {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.forgotPasswordWrapper a {
  color: black;
  font-size: 12px;
  margin-bottom: 24px;
  text-decoration: none;
}

.submitBtn {
  width: 100%;
}

.additionalLink {
  font-size: 14px;
  text-align: center;
}

.additionalLink a {
  color: var(--app-highlight-color);
  text-decoration: underline;
}

.goBackLink {
  color: black;
  font-size: 16px;
  text-align: center;
  opacity: 0.6;
}

.goBackLink:hover {
  color: black;
  opacity: 1;
}

.message {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.passwordStrengthBar > div > div {
  border-radius: 100px !important;
  height: 5px !important;
}

@media screen and (min-width: 100px) and (max-width: 1024px) {
  .wrapper {
    grid-template-columns: 1fr;
    position: relative;
    overflow: scroll;
  }

  .decorationImg {
    display: none;
  }

  .decorationImgSmallScreen {
    display: block;
    height: calc(100vh * 0.3);
    max-width: 50vw;
    position: absolute;
    right: 0px;
    object-fit: cover;
    overflow: visible;
    top: 0px;
    width: auto;
    z-index: 1;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper header {
    padding: 0px 10px;
  }

  .form,
  .contentWrapper {
    width: 90%;
  }
}
