@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

h1 {
  font-size: 70px;
  font-weight: 800;
  margin: 0px;
  text-align: center;
}

h2 {
  font-size: 68px;
  font-weight: 800;
  margin: 0px;
  text-align: center;
}

h3 {
  color: black;
  font-size: 32px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
}

.app {
  height: 100vh;
  min-height: var(--app-min-height);
}

.distanceTypeSwitch {
  background-color: #57BEC0;
  background-image: none;
}

.distanceTypeSwitch.ant-switch-checked {
  background-color: var(--app-dark-color);
}

.betaModal .ant-modal-content {
  border-radius: 34px;
}

.ant-input-number-group-addon,
.ant-input-group-addon,
.ant-picker-panel {
  background-color: transparent !important;
}

button.iubenda-tp-btn.iubenda-cs-preferences-link {
  visibility: hidden !important;
  z-index: 1 !important;
}

a.iubenda-tp-btn.iubenda-cs-preferences-link,
div.iubenda-uspr-btn 
{
  visibility: hidden !important;
  z-index: 1 !important;
}

// google map info window
button.gm-ui-hover-effect {
  visibility: hidden;
}

@media screen and (min-height: 100px) and (max-height: 950px) and (max-width: 500px){
  // #iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-visible .iubenda-cs-container {
  //   position: fixed !important;
  //   top: 630px !important;
  //   overflow: scroll !important;
  //   height: calc(100vh - 630px) !important;
  // }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .ant-modal {
    top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 44px;
  }
  
  h2 {
    font-size: 40px;
  }
  
  h3 {
    font-size: 26px;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
  
  h2 {
    font-size: 32px;
  }
  
  h3 {
    font-size: 20px;
  }

  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  h1 {
    font-size: 24px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 16px;
  }

  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }

  .ant-input-number-group-addon {
    padding: 0px 2px !important;
  }
}

@primary-color: #0068FF;@input-height-base: 48px;@input-placeholder-color: #36374a80;@border-radius-base: 4px;@btn-height-base: 48px;@btn-font-weight: 600;@font-size-base: 18px;@btn-default-ghost-color: @primary-color;@btn-default-ghost-border: @primary-color;@popover-padding-horizontal: 0px;@collapse-header-padding: 0px;@table-header-bg: transparent;