.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 100%;
}

.switchIcon {
  align-self: flex-end;
  cursor: pointer;
}

.searchButton {
  margin-top: 20px;
  width: 160px;
}
