
.doubleInputWrapper {
  align-items: end;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.formItemWrapper {
  width: 100%;
}

.formItemLabel {
  color: rgba(19, 19, 19, 0.80);
  font-size: 16px;
  font-weight: 400;
}

.formItemLabel span {
  color: var(--app-dark-color);
  font-weight: 700;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .doubleInputWrapper {
    grid-template-columns: 1fr;
  }
}
