.wrapper {
  background: #FFFFFF;
  border: 1px solid #CBD4E6;
  border-radius: 12px;
  cursor: pointer;
  padding: 12px;
  margin-bottom: 20px;
}

.wrapper.flightDetails:hover  {
  cursor: default;
  box-shadow: none;
}

.wrapper:hover {
  box-shadow: 0px 0px 4px rgba(25, 21, 89, 0.25);
}

.airlineInfo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; 
}

.airline {
  color: #57BEC0;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}

.flightSummaryTitle {
  color: #232323;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}

.stops {
  background: #57BEC0;
  border-radius: 100px;
  color: white;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 6px;
}

.infoWrapper {
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
}

.tripTime {
  color: #808080;
  font-size: 12px;
  line-height: 14px;
  margin-top: -10px;
  text-align: center;
}

.flightLocation {
  color: var(--app-dark-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.flightAdditionalInfo {
  color: #808080;
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0px;
}

.flightStopInfo {
  font-size: 14px;
}

.flightSummaryInfo {
  font-size: 16px;
  line-height: 22px;
  margin-top: -3px;
}

.flightDate {
  color: #232323;
  font-size: 12px;
  line-height: 16px;
}
