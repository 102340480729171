.wrapper {
  background-color: var(--app-background-color);
  background-size: cover;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height) calc(100% - 10px);
  height: 100%;
  overflow: hidden;
}

.wrapper.flight {
  background-image: url('./../../../../img/travel/byFlightBg.webp');
}

.wrapper.cruise {
  background-image: url('./../../../../img/travel/byCruiseBg.webp');
}

.wrapper header {
  padding: 0px var(--app-padding-left-right);
}

.wrapper header a {
  color: #E3E3E3;
}

.searchAreaWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0px 40px var(--header-height) 40px;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 5px;
}

.breadcrumbs {
  position: absolute;
  left: 60px;
  top: 0px;
}

/* .breadcrumbs span {
  color: #E3E3E3;
} */

.search {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  justify-content: center;
  max-height: 90%;
  padding: 30px;
  position: relative;
  overflow: scroll;
  width: 60%;
}

.search h1 {
  font-size: 44px;
}

.searchDescription {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  width: 100%;
}

.datePickerFooter {
  color: #0068FF;
  cursor: pointer;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.datePickerFooter:hover {
  color: #2986ff;
}

.searchButton {
  margin-top: 40px;
  width: 160px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .searchAreaWrapper {
    background-image: none;
    padding: 0px 10px var(--header-height) 10px;
  }

  .wrapper header {
    padding: 0px 10px;
  }

  .breadcrumbs {
    left: 10px;
  }

  .search {
    max-height: 100%;
    padding: 10px;
    width: 100%;
  }

  .searchButtonWrapper {
    width: 100%;
  }
  .searchButton {
    width: 100%;
  }  
}
