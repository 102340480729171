.map {
  height: 100vh;
  min-height: calc(100vh - var(--footer-height) - var(--header-height) - 200px);
  position: sticky !important;
  top: 0px;
  width: 100%;
}

.map.smallScreenClosed {
  visibility: hidden;
  height: 0px;
  min-height: 0px;
}

.legendIcon {
  cursor: pointer;
  height: 40px;
  margin: 10px;
  position:absolute;
  right: 0px;
  top: 110px;
  width: 40px;
  z-index: 1000;
}

.legendIcon path {
  fill: #666666;
}

.legendIcon:hover path {
  fill: black;
}

.markerInner {
  background-color: #fff;
  width: 260px;
  height: fit-content;
  min-height: 75px;
  position: relative;
  padding: 5px 10px;
  border-radius: 10px;
  top: -130px;
  left: -133px;
  z-index: 250;
  font-size: 13px;
}

.markerInner:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #fff;
  left: 50%;
  right: 50%;
  bottom: -12px;
  transform: rotate(270deg);
}

.markerInner p {
  margin-top: 10px;
  margin-bottom: 0px;
}

.markerInnerBtn {
  position: absolute;
  top: 2px;
  right: 6px;
  width: 14px;
  height: 14px;
  padding: 0px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.mapLegend h2 {
  font-size: 16px;
  margin-bottom: 10px;
}

.mapLegend h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0px;
  text-align: left;
}

.explanationItem {
  align-items: center;
  display: flex;
}

.explanationText {
  margin-bottom: 8px;
}

.smallScreenGoToResultsBtn {
  align-items: center;
  background-color: white;
  border: 1px solid var(--app-primary-color);
  border-radius: 20px;
  bottom: 10px;
  cursor: pointer;
  color: var(--app-primary-color);
  display: none;
  justify-content: center;
  left: 0px;
  margin: 0 auto;
  margin-top: 20px;
  right: 0px;
  padding: 8px 15px;
  position: absolute;
  width: 148px;
  z-index: 1000;
}

.smallScreenGoToResultsBtn svg {
  margin-right: 10px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .map {
    margin-bottom: 30px;
  }

  .legendIcon {
    top: 0px;
  }

  .smallScreenGoToResultsBtn {
    bottom: 20px;
    display: flex;
    position: sticky;
    margin-top: 10px;
  }
}
