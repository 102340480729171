.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  padding: 0px 60px 60px 60px;
}

.contentWrapper h1 {
  color: var(--app-dark-color);
  font-size: 36px;
  font-weight: 700;
  margin: 60px 0px;
}

.infoBlock {
  background: #F9F8FE;
  border-radius: 8px;
  color: rgba(19, 19, 19, 0.8);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 140%;
  padding: 15px;
  text-align: center;
}

.infoBlock h2 {
  align-self: center;
  color: #101828;
  display: block;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 20px;
  text-align: center;
}

.description {
  color: rgba(19, 19, 19, 0.8);
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 30px;
}

.point {
  color: #131313;
  column-gap: 5px;
  display: grid;
  font-size: 18px;
  grid-template-columns: 28px 1fr;
  line-height: 28px;
  margin: 20px;
}

.abilitiesBlockWrapper {
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 500px;
}

.abilitiesWrapper {
  margin-top: 50px;
}

.abilitiesWrapper div {
  column-gap: 5px;
  display: grid;
  grid-template-columns: 28px 1fr;
  margin-bottom: 12px;
}

@media screen and (min-width: 100px) and (max-width: 1095px) {
  .abilitiesBlockWrapper {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    padding: 0px 15px 30px 15px;
  }

  .contentWrapper h1 {
    font-size: 32px;
    margin: 25px 0px;
  }

  .infoBlock h2 {
    font-size: 28px;
    line-height: 120%;
  }
}
