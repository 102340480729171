:root {
  --app-padding-left-right: 60px;
  --app-min-height: 500px;
  --app-primary-color: #0068FF;
  --app-highlight-color: #1890FF;
  --app-background-color: #F9F8FE;
  --app-dark-color: #191559;
  
  --header-height: 72px;
  --footer-height: 170px;
  --footer-height-mobile: 210px;
}
