.wrapper {
  background-color: var(--app-background-color);
  min-height: calc(100vh - var(--footer-height));
}

.mapWrapper {
  height: 100%;
}

.header,
.breadcrumbsWrapper {
  padding: 0px var(--app-padding-left-right);
}

.blocksWrapper {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.informationWrapper {
  padding-left: var(--app-padding-left-right);
  overflow: hidden;
  width: 100%;
}

.breadcrumbsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.showMapSmallScreenBtn {
  align-items: center;
  background-color: white;
  border: 1px solid var(--app-primary-color);
  border-radius: 20px;
  bottom: 20px;
  cursor: pointer;
  color: var(--app-primary-color);
  display: flex;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 8px 15px;
  position: sticky;
  width: 148px;
  z-index: 100;
}

.showMapSmallScreenBtn svg {
  margin-right: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    background-color: var(--app-background-color);
    min-height: 100vh;
    padding: 0px;
  }

  .header,
  .breadcrumbsWrapper {
    padding: 30px;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    background-color: var(--app-background-color);
    min-height: 100vh;
    padding: 10px;
  }

  .blocksWrapper {
    grid-template-columns: 1fr;
  }

  .informationWrapper {
    padding-left: 0px;
    max-width: 100vw;
  }

  .header {
    padding: 0px;
  }

  .breadcrumbsWrapper {
    padding: 10px;
  }

  .resultCategories,
  .mapWrapper {
    max-width: 100vw;
  }
}
