.descriptionWrapper {
  background: #FFFFFF;
  border: 1px solid #CBD4E6;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 70px;
  margin-bottom: 15px;
  padding: 12px;
}

.descriptionTitle {
  font-size: 16px;
  font-weight: 600;
}

.descriptionText {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 130%;  
  margin: 12px 0px;
}

.cruisePhoto {
  border-radius: 12px;
  margin-bottom: 15px;
  object-fit: contain;
  width: 100%;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .descriptionWrapper {
    grid-template-columns: 1fr;
  }
}
