.filtersBtnWrapper {
  align-items: center;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #DDD;
  color: #222;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 48.08px;
  justify-content: center;
  line-height: 16px;
  margin-left: 10px;
  padding: 15px 10px;
  position: relative;
}

.filtersBtnWrapper svg {
  margin-right: 5px;
  width: 14px;
}

.filtersBtnWrapper svg path {
  fill: white;
  stroke: black;
}

.filterWrapper {
  padding: 0px 30px;
}

.label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px; 
  margin-bottom: 10px;
}

.filtersFooter {
  border-top: 2px solid rgba(0, 104, 255, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
}

.filtersFooter button {
  height: 30px;
  padding: 0px;
  width: 100px;
}

.filtersFooter button:first-of-type span {
  text-decoration: underline;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .filtersBtnWrapper {
    margin-top: 10px;
  }
}
