.blockWrapper,
.resultList {
  background: #FFFFFF;
  border-radius: 12px;
}

.goBackBtn {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
}

.goBackBtn svg {
  margin-right: 7px;
}

.resultInnerLinkText {
  font-size: 16px;
}

.searchBarWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sortWrapper {
  display: flex;
}

.searchInput {
  width: 150px;
}

.cruisesBtnWrapper {
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  padding: 5px 10px 5px 10px;
}

.cruisesBtnTitle {
  align-items: center;
  display: flex;
}

.cruisesBtnTitle img {
  height: 44px;
  margin-right: 12px;
  width: 44px;
}

.modalTitle {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.modalTitle span {
  color: #191559;
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
}


.cruiseWrapper {
  align-items: center;
  background: #F9FAFB;
  border-bottom: 1px solid #EAECF0;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 45px;
  min-height: 56px;
  padding: 6px 24px;
}

.cruise {
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 70px 1fr;
}

.cruiseColumn {
  color: #101828;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.cruiseColumn span {
  color: #667085;
  font-size: 12px;
}

.codeColumn {
  background: #ECFDF3;
  border-radius: 16px;
  color:#027A48;
  font-size: 12px;
  height: fit-content;
  line-height: 18px;
  padding: 2px 8px;
  text-align: center;
}

.codeColumn.mobile {
  display: none;
}

.viewCruiseSearchBtn {
  font-size: 14px;
  font-weight: 700;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .collapseHeaderWrapper {
    align-items: flex-start;
    flex-direction: column;
  }

  .searchBarWrapper {
    flex-direction: column;
    margin-top: 20px;
  }

  .sortWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .searchInput {
    width: 100%;
  }

  .cruise {
    grid-template-columns: 1fr;
  }

  .codeColumn {
    width: fit-content;
  }

  .codeColumn.desktop {
    display: none;
  }

  .codeColumn.mobile {
    display: block;
  }

  .viewCruiseSearchBtn {
    text-align: center;
  }
}
