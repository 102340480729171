.blockWrapper,
.resultList {
  background: #FFFFFF;
  border-radius: 12px;
}

.goBackBtn {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
}

.goBackBtn svg {
  margin-right: 7px;
}

.collapseHeaderWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  width: 100%;
}

.airlinesBtnWrapper {
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  padding: 5px 10px 5px 10px;
}

.airlinesBtnTitle {
  align-items: center;
  display: flex;
}

.airlinesBtnTitle img {
  height: 44px;
  margin-right: 12px;
  width: 44px;
}

.searchBarWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modalTitle {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.modalTitle span {
  color: #191559;
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
}

.filtersWrapper {
  position: relative;
}

.filtersBtn {
  align-items: center;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #DDD;
  color: #222;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  line-height: 16px;
  margin-left: 10px;
  padding: 10px;
  position: relative;
}

.filtersBtn svg {
  margin-right: 5px;
  width: 14px;
}

.filtersBtn svg path {
  fill: white;
  stroke: black;
}

.radiusFilter {
  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  right: 0px;
  padding: 20px 12px;
  position: absolute;
  top: 50px;
  width: 300px;
}

.searchInput {
  width: 150px;
}

.airlineWrapper {
  align-items: center;
  background: #F9FAFB;
  border-bottom: 1px solid #EAECF0;
  column-gap: 20px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  min-height: 56px;
  padding: 6px 24px;
}

.airlineWrapper:hover {
  background: #d7d7d7;
}

.airlineColumn {
  color: #101828;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.additionalData {
  color: #667085;
  font-size: 12px;
}

.codeColumn {
  background: #ECFDF3;
  border-radius: 16px;
  color:#027A48;
  font-size: 12px;
  line-height: 18px;
  padding: 2px 8px;
  text-align: center;
}

.goBack {
  align-items: center;
  color: #27273F;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}

.goBack svg {
  margin-right: 10px;
}

.goBack:hover {
  color: #0068FF;
}

.goBack:hover svg path {
  fill: #0068FF;
}

.airlineAdditionalAirportData {
  padding: 3px 0px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .collapseHeaderWrapper {
    align-items: flex-start;
    flex-direction: column;
  }

  .searchBarWrapper {
    flex-direction: column;
    margin-top: 20px;
  }

  .searchInput {
    width: 100%;
  }
}
