.verticalSuggestions {
  display: flex;
  flex-direction: column;
  height: 30px;
  overflow: visible;
  z-index: 100;
}

.verticalSuggestions input {
  height: 30px;
  max-width: 300px;
}

.suggestionsWrapper {
  background-color: white;
  padding: 8px;
  z-index: 100;
}

.suggestionsWrapper div {
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}

.suggestionsWrapper .active {
  background-color: var(--app-highlight-color);
}
