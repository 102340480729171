.resultCategories {
  display: grid;
  grid-template-columns: repeat(6, minmax(auto, 100px));
  overflow: auto;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
}

.resultCategories.smallScreen {
  display: none;
}

.resultType {
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #C2C2C2;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  user-select: none;
  width: 100px;
}

.resultType.active {
  background-color: var(--app-primary-color);
  color: white;
}

.resultType:not(:last-of-type) {
  margin-right: 5px;
}

.resultType:first-of-type {
  margin-left: 10px;
}

.resultType:last-of-type {
  margin-right: 10px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .resultCategories.smallScreen {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    overflow: auto;
  }
}
