.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 15px;
}

h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 10px;
}

.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.toolbar svg {
  cursor: pointer;
}

.searchBar {
  align-items: center;
  display: flex;
}

.activeListType path {
  fill: var(--app-primary-color);
}

.search,
.search input,
.search::selection {
  background-color: transparent !important;
  background: transparent !important;
  border-color: transparent !important;
  border: none;
  box-shadow: none;
}

.emptyListWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.emptyListWrapper a {
  color: var(--app-primary-color);
  font-size: 20px;
  text-decoration-line: underline;
}

.emptyListText {
  color: #CFCFCF;
  font-size: 48px;
}

.cardViewWrapper {
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 315px);
  justify-content: center;
  margin-top: 20px;
  row-gap: 20px;
}

.eventTable {
  margin-top: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .toolbarIcon {
    display: none;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .toolbarIcon {
    display: none;
  }

  .toolbar {
    flex-direction: column;
  }

  .searchBar {
    margin-top: 15px;
    width: 100%;
  }
  
  .eventKindTool {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  h2 {
    align-items: flex-start;
    flex-direction: column;
  }

  .toolbarIcon {
    display: none;
  }

  .toolbar {
    flex-direction: column;
  }

  .searchBar {
    margin-top: 15px;
    width: 100%;
  }

  .eventKindTool {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}
