.form {
  max-width: 850px;
  width: 100%;
}

.label {
  font-size: 16px;
}

.btnAreaWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.btnAreaWrapper button:first-of-type {
  margin-right: 10px;
}
