.wrapper {
  background-color: var(--app-background-color);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  padding: 0px 60px 60px 60px;
  overflow: hidden;
  width: 100vw;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 450px minmax(350px, 1fr);
  height: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .wrapper {
    padding: 0px 10px 20px 10px;
  }

  .divider {
    margin: 10px 0px;
  }

  .contentWrapper {
    grid-template-rows: auto auto;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  .wrapper {
    padding: 0px 10px 20px 10px;
  }

  .divider {
    margin: 10px 0px;
  }

  .contentWrapper {
    grid-template-rows: auto auto;
  }
}
