.wrapper {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding: 15px;
  width: 315px;
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.date {
  font-size: 18px;
  font-weight: 500;
  margin-right: 6px;
}

.dateDetails {
  color: #161B25;
  font-size: 12px;
  font-weight: 600;
}

.day {
  color: #B2B4B8;
  font-size: 12px;
  margin-left: 6px;
  text-transform: uppercase;
}

.code {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
}

.code span {
  font-weight: 600;
  margin-left: 6px;
}

.code svg {
  cursor: pointer;
}

.code svg:hover path {
  stroke: var(--app-primary-color);
}

.eventDetailsTagWrapper {
  align-items: center;
  background: rgba(2, 201, 79, 0.1);
  border: 1px solid rgba(2, 201, 79, 0.1);
  border-radius: 12px;
  color: #02C94F;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  line-height: 12px;
  padding: 4px 6px;
}

.copyLink {
  align-items: center;
  color: var(--app-primary-color);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  margin-right: 5px;
}

.copyLink svg {
  margin-right: 5px;
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  .title {
    font-size: 14px;
    font-weight: 700;
  }
}
