.resultCategoryTitle {
  color: var(--app-primary-color);
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0px 10px 0px;
  padding: 0px 15px;
  text-align: start;
  text-transform: capitalize;
}

.resultSubCategoryTitle {
  color: var(--app-primary-color);
  font-size: 14px;
  margin: 0px 0px 10px 0px;
  text-align: start;
  text-transform: capitalize;
}

.resultItem {
  align-items: center;
  border-bottom: 1px solid #E9E8FC;
  column-gap: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 70px;
  padding: 6px 15px;
}

.resultItem.withLink {
  grid-template-columns: 1.5fr 0.5fr;
}

.resultItem.nodeDetails {
  grid-template-columns: 1fr;
  min-height: 100px;
  padding: 0px 15px;
}

.resultItem.stopItem,
.resultItem.serviceItem {
  grid-template-columns: 1fr;
  min-height: 35px;
}

.resultItem.stopItem:hover {
  background-color: #FCFBFF;
}

.resultItem.selectedStopItem {
  border-left: 3px solid #0068FF;
  padding-left: 10px !important;
}

.resultItem svg:not(.archedArrow),
.linkIcon {
  /* background: rgba(196, 221, 253, 0.2); */
  border-radius: 10px;
  cursor: pointer;
  grid-column: 2 / 4;
  height: 50px;
  justify-self: end;
  padding: 10px;
  width: 55px;
}

.linkIcon {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.linkIcon svg {
  height: 20px;
  padding: 0px;
  width: 40px;
}

.linkIcon:hover {
  background-color: #F5F5FD;
  border-radius: 50%;
}

.linkIcon:hover svg path {
  stroke: black;
}

.resultItem img {
  justify-self: center;
}

.archedArrow {
  grid-column: none;
  height: 10px;
  justify-self: start;
  margin-left: 5px;
  padding: 0px;
  width: 10px;
}

.logo {
  border-radius: 50%;
  height: 40px;
  margin-right: 10px;
  object-fit: contain;
  width: 40px;
}

.resultItem button {
  justify-self: end;
  width: 100px;
}

.resultTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  width: fit-content;
}

.stopItem .resultTitle, 
.serviceItem .resultTitle {
  font-size: 16px;
}

.stopItem .resultTitle {
  cursor: pointer;
}

.terminalInfo {
  align-items: center;
  background: #EFF8FF;
  border-radius: 16px;
  color: #59BAB4;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  padding: 2px 8px;
  vertical-align: top;
  width: fit-content;
}

.terminalInfo.port-terminals {
  background: #FFFAEB;
  color: #ED9115;
}

.stopTitle:hover {
  color: var(--app-primary-color);
}

.stopAgency {
  align-items: center;
  display: flex;
  font-size: 10px;
  margin-top: -8px;
  width: fit-content;
}

.stopAgency a {
  align-items: center;
  color: black;
  display: flex;
}

.resultInfo {
  font-size: 14px;
}

.additionalInfoWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.detailsBtn {
  color: #3E86F5;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.additionalInfoFields {
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 10px;
}

.noResultsForCategory {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 30px;
}

.noResultsForCategory button {
  margin-top: 20px;
}

.directAirlinesShowMore {
  background-color: lightgray;
  border-radius: 30px;
  color: black;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  padding: 3px 5px;
  white-space: nowrap;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .additionalInfoWrapper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
  }

  .additionalInfoWrapper img,
  .additionalInfoWrapper button {
    margin-bottom: auto;
  }
}
