.map {
  height: 100vh;
  max-height: 100vh;
  position: sticky !important;
  top: 0;
}

.poweredBy {
  align-items: self-start;
  background: rgba(255, 255, 255, 0.80);
  border-radius: 4px;
  bottom: 16px;
  color: black;
  display: flex;
  font-size: 14px;
  padding: 3px;
  position: absolute;
  right: 10px;
}

.poweredBy:hover {
  color: black;
}

.poweredBy img {
  margin-left: 8px;
  width: 100px;
}
