.activitiesWrapper {
  margin-top: 20px;
}

.goBackBtnWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  justify-content: space-between;
  line-height: 25px;
  margin-bottom: 20px;
}

.goBackBtn {
  width: fit-content;
}

.goBackBtn svg {
  margin-right: 15px;
}

.goBackBtn:hover {
  color: #0068FF;
}

.goBackBtn:hover svg path {
  fill: #0068FF;
}

.filtersBtn {
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 5px 10px;
}

.activityItemWrapper {
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 5px;
}

.activityItem {
  display: flex;
}

.activityItem img {
  border-radius: 12px;
  margin-right: 15px;
  object-fit: cover;
  width: 120px;
}

.activityItemInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainDataWrapper {
  column-gap: 5px;
  display: grid;
  grid-template-columns:1fr 100px;
}

.activityTitle,
.price {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.activityLocationData {
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 10px 0px;
}

.price {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.activitiesBtnWrapper {
  align-items: center;
  background: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  padding: 5px 10px 5px 10px;
}

.activitiesBtnTitle {
  align-items: center;
  display: flex;
}

.activitiesBtnTitle img {
  height: 44px;
  margin-right: 12px;
  width: 44px;
}

.resultInnerLinkText {
  font-size: 16px;
}

.filtersTitle {
  border-bottom: 2px solid rgba(0, 104, 255, 0.12);
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0px;
  text-align: center;
}

.filterBlockWrapper {
  padding: 20px 30px;
}

.filterBlockTitle {
  font-size: 24px;
  font-weight: 600;
}

.priceFieldsWrapper {
  align-items: center;
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  margin-top: 20px;
  text-align: center;
}

.rateOptionsWrapper {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.rateOption,
.selectedRateOption {
  border: 1px solid #DDDDDD;
  border-radius: 30px;
  color: var(--app-primary-color);
  cursor: pointer;
  margin-right: 10px;
  padding: 5px 20px;
  user-select: none;
}

.selectedRateOption {
  background-color: var(--app-primary-color);
  color: white;
}

.durationOptionsWrapper {
  margin-top: 15px;
}

.filtersFooter {
  border-top: 2px solid rgba(0, 104, 255, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
}

.filtersFooter button {
  height: 30px;
  padding: 0px;
  width: 100px;
}

.filtersFooter button:first-of-type span {
  text-decoration: underline;
}

.transportStopsBtn {
  align-items: center;
  border-radius: 12px;
  border: 1px solid #0068FF;
  color: #0068FF;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  margin-top: 10px;
  padding: 15px 6px;
  user-select: none;
  width: 145px;
}

.rateWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .mainDataWrapper {
    grid-template-columns:1fr;
  }

  .rateWrapper {
    margin-bottom: 25px;
    margin-top: -20px;
    position: relative;

    flex-direction: column;
    align-items: self-start;
  }

  .transportStopsBtn {
    height: 40px;
    text-align: center;
    width: 115px;
  }
}
