.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  width: 100%;
}

.label {
  font-size: 16px;
}

.description {
  color: #667085;
  font-size: 14px;
}

.doubleInput {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.validationError {
  color: #ff4d4f;
  margin: 40px 0px 5px 0px;
  text-align: end;
}

.generateBtn {
  align-self: flex-end;
  margin-bottom: 40px;
}
