.feedbackBtn {
  align-items: center;
  background-color: white;
  border-radius: 8px;
  color: var(--app-dark-color);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0px 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form h2 {
  color: var(--app-dark-color);
  font-size: 36px;
  font-weight: 600;
}

.label {
  font-size: 18px;
}

.additionalLabel {
  color: var(--app-dark-color);
  font-size: 18px;
  font-weight: 700;
}

.policyMessage {
  color: #6F6C90;
  font-size: 14px;
  margin-left: 10px;
}

.btnAreaWrapper {
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .feedbackBtn {
    margin-top: 10px;
  }
}
