.share {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
}

.share svg {
  margin-right: 6px;
}

.share:hover {
  background: rgba(231, 226, 255, 0.6);
  border-radius: 4px;
}

.modalContent {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modalContent h3 {
  font-size: 18px;
  font-weight: 600;
}

.description {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0px;
  text-align: center;
  white-space: pre-wrap;
}

.btnArea {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
  width: 100%;
}
