.wrapper {
  border-radius: 12px;
  color: black;
  column-gap: 16px;
  display: grid;
  grid-template-columns: 50px 1fr 100px;
  height: fit-content;
  margin: 10px;
  min-height: 82px;
  padding: 10px;
}

.bannerFromCodeWrapper {
  border-radius: 12px;
  color: black;
  height: 82px;
  margin: 10px;
  overflow: hidden;
}

.logo {
  border-radius: 50%;
  height: 47px;
  object-fit: cover;
  width: 47px;
}

.mainDataWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.name {
  font-weight: 700;
  font-size: 18px;
}

.category {
  background: #F9F8FE;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  padding: 3px 6px;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
}

.description {
  font-size: 12px;
  height: fit-content;
  min-height: 20px;
  /* height: 20px; */
}

.decoration1 {
  position: absolute;
  transform: rotate(180deg);
  right: 0px;
  top: -12px;
  z-index: 1;
}

.decoration2 {
  position: absolute;
  right: -100px;
  top: -15px;
  z-index: 1;
}

.contacts {
  align-items: center;
  color: var(--app-primary-color);
  display: flex;
  font-size: 11px;
  font-weight: 600;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    display: flex;
    height: 100%;
    max-width: 88vw;
    overflow: hidden;
  }

  .description {
    height: fit-content;
  }
}
