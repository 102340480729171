.modalContent {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modalContent h3 {
  font-size: 18px;
  font-weight: 600;
}

.description {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0px;
  text-align: center;
  white-space: pre-wrap;
}

.btnArea {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
  width: 100%;
}

.saveIcon {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
}

.saveIcon svg {
  margin-right: 6px;
}

.saveIcon:hover {
  background: rgba(231, 226, 255, 0.6);
  border-radius: 4px;
}

.saveOptionsTitle {
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 15px;
  user-select: none;
}

.saveOptions {
  max-height: 200px;
  overflow: auto;
}

.saveOption {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
}

.saveOption:hover {
  background: #F9F8FE;
}
