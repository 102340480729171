.wrapper {
  background-color: var(--app-background-color);
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.infoBlock {
  position: relative;
}

.logo {
  color: rgba(5, 109, 250, 0.45);
  font-size: 40px;
  font-weight: 500;
  padding: 20px;
}

.resultsWrapper {
  background: rgba(255, 255, 255, 0.80);
  border-radius: 8px 0px 0px 8px;
  margin: 0px 12px 12px 12px;
  padding: 12px;
  position: relative;
}

.goBack {
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 30px;
}

.goBack svg {
  margin-right: 6px;
}

.tabIconWrapper {
  align-items: center;
  color: var(--app-primary-color);
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  left: 50%;
  position: absolute;
  top: -50px;
  transform: translateX(-50%);
}

.tabIconWrapper svg {
  width: 40px;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
