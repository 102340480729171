.collapseBtnWrapper,
.blockWrapper,
.resultList {
  background: #FFFFFF;
  border-radius: 12px;
  margin-top: 14px;
}

.collapseBtnWrapper {
  padding: 5px 10px 5px 25px;
}
.blockWrapper {
  padding: 5px 10px 5px 10px;
}

.goBackBtn {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
}

.goBackBtn svg {
  margin-right: 7px;
}
