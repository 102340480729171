.wrapper {
  column-gap: 20px;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, 360px);
  justify-content: center;
  margin-bottom: 20px;
  row-gap: 20px;
  width: 100%;
}

.cruise {
  background: #FAFBFC;
  border-radius: 4px;
  padding: 15px 12px;
  width: 360px;
}

.cardHeader {
  color: #57BEC0;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  position: relative;
}

.cardTitle {
  color: #101828;
  font-size: 24px;
  font-weight: 600;
}

.cardDescription {
  color: rgba(16, 24, 40, 0.60);
  font-size: 16px;
  font-weight: 600;
}

.searchLink {
  align-items: center;
  color: #0068FF;
  display: flex;
  font-size: 16px;
  font-weight: 400;
}

.searchLink a:hover {
  color: var(--app-dark-color);
}

.copyIcon {
  cursor: pointer;
}

.copyIcon:hover g path {
  stroke: var(--app-dark-color);
}

.tagsWrapper {
  display: flex;
  margin-top: 18px;
}

.dateTag,
.daysTag {
  background: var(--primary-50, #F9F5FF);
  border-radius: 16px;
  color: #57BEC0;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  width: fit-content;
}

.daysTag {
  background: var(--blue-50, #EFF8FF);
  color: #175CD3;
  margin-left: 8px;
}
