.noResult {
  font-size: 24px;
  padding-top: 30px;
  text-align: center;
}

.nodeResultCard {
  border-bottom: 0.556px solid #E9E8FC;
  display: grid;
  grid-template-columns: 1fr 50px;
  padding: 18px 5px 5px 5px;
}

.nodeName {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
}

.nodeTerminalsTag {
  align-items: center;
  background: #EFF8FF;
  border-radius: 16px;
  color: #59BAB4;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  margin-left: 6px;
  padding: 2px 8px;
  vertical-align: top;
  width: fit-content;
}

.nodeTerminalsTag.port-terminals {
  background: #FFFAEB;
  color: #ED9115;
}

.nodeDistance {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
}

.stopType {
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0px 10px 0px;
  text-align: start;
  text-transform: capitalize;
}

.stopRadiusInfo {
  font-size: 14px;
  font-weight: 400;
  margin-left: 7px;
  text-transform: lowercase;
  opacity: 0.5;
}

.activityItemWrapper {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 5px;
}

.activityItem {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 45px 1fr 90px;
}

.activityItem img {
  border-radius: 50%;
  height: 44px;
  margin-right: 15px;
  object-fit: cover;
  width: 44px;
}

.activityTitle {
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.activityPrice {
  color: black;
  font-size: 14px;
  font-weight: 600;
  width: 90px;
}
