.sortBtn {
  align-items: center;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #DDD;
  color: #222;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 48.08px;
  justify-content: center;
  line-height: 16px;
  padding: 15px 10px;
  position: relative;
}

.sortBtn.active {
  border-color: #665fe8;
  box-shadow: 0 0 0 2px rgba(58, 54, 219, 0.2);
  outline: 0;
}

.sortOptions {
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #DDD;
  cursor: default;
  padding: 10px 0px;
  position: absolute;
  top: 50px;
  z-index: 100;
}

.sortOption {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 5px 15px;
}

.sortOption.active {
  background-color: #d7d7d7;
}

.sortOption svg {
  margin-left: 5px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .sortBtn {
    margin-top: 10px;
    width: 100%;
  }

  .sortOptions {
    width: 100%;
  }

  .sortOption {
    font-size: 20px;
    padding: 15px 15px;
  }
}
