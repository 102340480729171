.wrapper {
  background-color: var(--app-background-color);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height) calc(100% - 10px);
  height: 100%;
  overflow: hidden;
}

.wrapper header {
  padding: 0px var(--app-padding-left-right);
}

.searchAreaWrapper {
  align-items: center;
  background-image: url('./../../../img/travel/searchBg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 50%;
  background-size: contain;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: var(--header-height);
  position: relative;
}

.breadcrumbs {
  position: absolute;
  left: 60px;
  top: 0px;
}

.search {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  justify-content: center;
  max-height: 90%;
  padding: 30px;
  position: relative;
  overflow: scroll;
  width: 60%;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 5px;
}

.search h1 {
  font-size: 44px;
}

.searchDescription {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchRequestBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchAdditionalFields {
  width: calc(100% - 44px);
}

.locationInput {
  align-items: center;
  display: flex;
  width: 100%;
}

.locationInput div {
  width: 100%;
}

.searchRequestBlockDivider {
  grid-column: 1 / 4;
  padding-bottom: 20px;
}

.addRemoveFieldBtn {
  border: 0.5px solid rgba(0, 104, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  margin: 0px 0px 20px 10px;
  padding: 6px;
  width: 34px;
}

.addRemoveFieldBtnSmallScreen {
  align-items: center;
  cursor: pointer;
  display: none;
  justify-content: center;
  margin-top: 15px;
}

.addRemoveFieldBtnSmallScreen svg {
  border: 0.5px solid rgba(0, 104, 255, 0.2);
  border-radius: 50%;
  height: 34px;
  margin-right: 10px;
  padding: 6px;
  width: 34px;
}

.searchButtonWrapper {
  margin-top: 40px;
}

.validationError {
  text-align: center;
}

.searchButton {
  width: 160px;
}

.helpIcon {
  cursor: pointer;
  margin-top: 10px;
}

.helpIcon:hover g path {
  stroke: var(--app-primary-color);
  stroke-opacity: 1;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .searchAreaWrapper {
    background-image: none;
  }

  .wrapper header {
    padding: 0px 10px;
  }

  .breadcrumbs {
    left: 10px;
  }

  .addRemoveFieldBtn {
    display: none;
  }

  .addRemoveFieldBtnSmallScreen {
    display: flex;
  }

  .search {
    max-height: 100%;
    padding: 10px;
    width: 90%;
  }

  .searchAdditionalFields {
    width: 100%;
  }

  .searchButtonWrapper,
  .searchButton {
    width: 100%;
  }  
}
