.noResultsMessage {
  margin-top: 20px;
}

.stopType {
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0px 10px 0px;
  text-align: start;
  text-transform: capitalize;
}

.radiusInfo {
  font-size: 14px;
  font-weight: 400;
  margin-left: 7px;
  text-transform: none;
  opacity: 0.5;
}
