.results {
  column-gap: 18px;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-template-columns: none;
  padding-bottom: 28px;
  position: relative;
}

.resultInner {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 12px 12px 25px 10px;
  margin-top: 14px;
}

.bigScreenResults {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.searchAdditionalFields {
  margin-bottom: 10px;
}

.openServicesNodeName {
  color: #27273F;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 15px;
  margin-top: 7px;
}

.nodeDetailsGoBack {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  width: fit-content;
}

.nodeDetailsGoBack svg {
  margin-right: 15px;
}

.nodeDetailsGoBack:hover {
  color: #0068FF;
}

.nodeDetailsGoBack:hover svg path {
  fill: #0068FF;
}

.resultInnerLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultInnerLink + .resultInnerLink {
  margin-top: 16px;
}

.resultInnerLinkText {
  width: 100%;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin: 0;
}

.resultInnerBtn {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  color: #0068FF;
  font-weight: 700;
  margin: 0;
  min-width: 98px;
  transition: all .3s
}

.resultInnerBtn:hover {
  text-decoration: underline;
}

.resultsText {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.resultList {
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 0px;
}

.mapWrapper {
  position: relative;
}

.showMapSmallScreenBtn {
  align-items: center;
  background-color: white;
  border: 1px solid var(--app-primary-color);
  border-radius: 20px;
  cursor: pointer;
  color: var(--app-primary-color);
  display: none;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 8px 15px;
  width: 148px;
}

.showMapSmallScreenBtn svg {
  margin-right: 10px;
}

@media screen and (max-width: 1440px) {
  .resultInnerLinkText{
    width: 72%;
    color: #000;
    margin: 0;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .results {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    grid-template-columns: 1fr;
  }

  .searchAdditionalFields,
  .filters,
  .serviceOptions,
  .mapWrapper {
    display: none !important;
  }

  .showMapSmallScreenBtn {
    display: flex;
    position: sticky;
    bottom: 20px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    z-index: 100;
  }

  .hiddenResults {
    display: none;
  }
}
