.stopItemWrapper {
  align-items: flex-start;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 50px 1fr 150px;
  margin-bottom: 15px;
}

.stopItemWrapper .additionalInfo {
  color: #808080;
  margin: 0px;
}

.location {
  color: var(--app-dark-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.additionalInfo {
  color: #161B25;
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0px;
}

.additionalInfo span {
  color: #808080;
  margin-right: 5px;
}

.flightStopInfo {
  font-size: 14px;
}

.terminalsCount {
  align-items: center;
  background: #EFF8FF;
  border-radius: 16px;
  color: #59BAB4;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  margin-left: 8px;
  padding: 2px 8px;
  vertical-align: top;
  width: fit-content;
}

.terminalsCount.byCruise {
  background: #FFFAEB;
  color: #ED9115;
}

.addLocationBtn {
  color: #0068FF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.addedLocationWrapper {
  align-items: center;
  color: #57BEC0;
  cursor: default;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  margin-right: 20px;
}

.addedLocationWrapper svg {
  cursor: pointer;
  height: 15px;
  width: 100%;
}

.addedLocationWrapper svg.arrow:hover path{
  fill: #0068FF;
}

.addedLocationWrapper svg.delete:hover path{
  stroke: #0068FF;
}

.addedLocationActions {
  display: grid;
  grid-template-columns: 15px 15px 15px;
  margin-left: 10px;
}
