.wrapper header {
  padding: 0px var(--app-padding-left-right);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 240px);
  padding: 0px var(--app-padding-left-right);
}

.pageTitle {
  margin-bottom: 20px;
}

.pageTitle h1 {
  color: var(--app-dark-color);
  font-size: 24px;
  margin: 40px 0px 10px 0px;
  text-align: start;
}

.settingsArea {
  display: grid;
  grid-template-columns: 200px 1fr;
  min-height: calc(100vh - 240px - 150px);
}

.settingsTabsMobile {
  display: none;
}

.settingsTabs {
  list-style-type: none;
  user-select: none;
  padding-left: 0px;
}

.settingsTabs li {
  padding: 10px 0px;
  white-space: nowrap;
}

.settingsTabs li.active {
  font-weight: 700;
}

.settingsTabs li:hover:not(.active) {
  cursor: pointer;
  color: var(--app-dark-color);
}

.formArea {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: auto;
  width: 100%;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper header {
    padding: 0px 10px;
  }

  .contentWrapper {
    padding: 0px 10px;
  }

  .settingsArea {
    grid-template-columns: 1fr;
  }

  .settingsTabs {
    display: none;
  }

  .settingsTabsMobile {
    display: block;
    margin-bottom: 30px;
  }
}
