.additionalInputs {
  align-items: center;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
}

.additionalInputsSelect > div{
  height: 40px !important;
  border-radius: 10px !important;
}

.additionalInputsSelect span{
  line-height: 38px !important;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .numberInputIcon {
    display: none;
  }
}
