.wrapper {
  background-color: var(--app-background-color);
  min-height: calc(100vh - var(--footer-height));
}

.header {
  padding: 0px var(--app-padding-left-right);
}

.breadcrumbsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px var(--app-padding-left-right);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    background-color: var(--app-background-color);
    min-height: 100vh;
    padding: 0px;
  }

  .header {
    padding: 30px;
  }

  .breadcrumbsWrapper {
    padding: 30px;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    background-color: var(--app-background-color);
    min-height: 100vh;
    padding: 10px;
  }

  .header {
    padding: 0px;
  }

  .breadcrumbsWrapper {
    padding: 10px;
  }
}
