.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 100%;
}

.locationsWrapper {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.location {
  display: flex;
  flex-direction: column;
}

.locationTitle {
  color: #191559;
  font-size: 18px;
  font-weight: 700;
}

.locationName {
  color: #191559;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.airport {
  align-items: center;
  background: var(--True-White, #FFF);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: var(--for-text, rgba(19, 19, 19, 0.80));
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px 12px;
  width: 100%;
}

.airport svg {
  cursor: pointer;
}

.btnWrapper {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  width: 100%;
}

.btnWrapper a,
.btnWrapper button {
  width: 100%;
}

.goBack {
  color: rgba(19, 19, 19, 0.80);
  font-size: 16px;
  margin-top: 20px;
  text-decoration-line: underline;
}

.goBack:hover {
  color: rgba(19, 19, 19, 0.80);
}
