.wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}

.crumb {
  color: rgba(0, 0, 0, 0.45);
}

.currentCrumb {
  color: #191559;
  font-weight: 700;
}

.separator {
  margin: 0px 5px;
}

.tools {
  align-items: center;
  display: flex;
}

.highlightBg {
  align-items: center;
  background: #FDFCFF;
  display: flex;
  padding: 0px 10px;
}

.goToPrev {
  display: none;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .linksChain {
    display: none;
  }

  .highlightBg {
    background: transparent;
    padding: 0px;
  }

  .goToPrev.highlightBg a {
    color: var(--app-dark-color);
  }

  .goToPrev.highlightBg a svg path {
    fill: var(--app-dark-color);
  }

  .goToPrev {
    display: flex;
  }

  .goToPrev a {
    align-items: center;
    color: #F9F8FE;
    display: flex;
  }

  .goToPrev a svg {
    margin-right: 7px;
  }

  .goToPrev a svg path {
    fill: #F9F8FE;
  }
}
