.wrapper {
  background-color: var(--app-background-color);
  min-height: calc(100vh - var(--footer-height));
}

.header {
  padding: 0px var(--app-padding-left-right);
}

.breadcrumbsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px var(--app-padding-left-right);
}

.tabs {
  padding: 0px 10px;
}

.tabs {
  padding-left: var(--app-padding-left-right);
}

.tabsWrapper {
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 10px;
}

.tab {
  align-items: center;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  display: flex;
  padding: 12px 17px;
  position: relative;
  margin-right: 5px;
}

.tab h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}

.tab.active {
  background: #FFFFFF ;
}

.tab.active h2 {
  color: var(--app-highlight-color);
}

.tab button {
  height: 30px;
  margin-left: 10px;
  padding: 0px 10px;
}

.results {
  column-gap: 18px;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-template-columns: none;
  padding-bottom: 28px;
  position: relative;
}

.blockWrapper {
  background: white;
  border-radius: 10px;
  padding: 12px 15px;
}

.mapWrapper {
  position: relative;
}

.showMapSmallScreenBtn {
  align-items: center;
  background-color: white;
  border: 1px solid var(--app-primary-color);
  border-radius: 20px;
  cursor: pointer;
  color: var(--app-primary-color);
  display: none;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 8px 15px;
  width: 148px;
}

.showMapSmallScreenBtn svg {
  margin-right: 10px;
}

.airportCard {
  align-items: center;
  border-bottom: 1px solid #E9E8FC;
  column-gap: 18px;
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  min-height: 70px;
  padding: 6px 0px;
}

.title {
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  line-height: 33px;
  width: fit-content;
}

.terminals {
  align-items: center;
  background: #EFF8FF;
  border-radius: 16px;
  color: #59BAB4;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  padding: 2px 8px;
  vertical-align: top;
  width: fit-content;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    background-color: var(--app-background-color);
    min-height: 100vh;
    padding: 0px;
  }

  .header {
    padding: 30px;
  }

  .breadcrumbsWrapper {
    padding: 30px;
  }

  .tabs {
    padding-left: 30px;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .wrapper {
    background-color: var(--app-background-color);
    min-height: 100vh;
    padding: 10px;
  }

  .header {
    padding: 0px;
  }

  .breadcrumbsWrapper {
    padding: 10px;
  }

  .tabs {
    padding-left: 0px;
  }

  .results {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    grid-template-columns: 1fr;
  }

  .blockWrapper {
    padding: 5px;
    width: 93vw;
    justify-self: center;
  }

  .mapWrapper {
    display: none !important;
  }

  .showMapSmallScreenBtn {
    display: flex;
    position: sticky;
    bottom: 20px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    z-index: 100;
  }

  .hiddenResults {
    display: none;
  }
}
