.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  padding: 0px 60px 60px 60px;
}

.contentWrapper h1 {
  color: var(--app-dark-color);
  font-size: 36px;
  font-weight: 700;
  margin: 60px 0px;
}

.infoBlock {
  background: #F9F8FE;
  border-radius: 8px;
  color: rgba(19, 19, 19, 0.8);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 140%;
  padding: 15px;
  text-align: center;
}

.infoBlock h2 {
  align-self: center;
  color: #101828;
  display: block;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 20px;
  text-align: center;
}

.searchInput {
  margin: 0 auto;
  width: 320px;
}

.previewWrapper {
  column-gap: 32px;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, 385px);
  justify-content: center;
  margin-top: 40px;
  row-gap: 32px;
}

.loadMore {
  align-items: center;
  background: #E1EDFF;
  border: 1px solid #F9F5FF;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--app-primary-color);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 30px;
  padding: 12px 20px;
  width: 150px;
}

.loadMore svg {
  margin-right: 10px;
}

.loadMore:hover {
  border-color: var(--app-primary-color);
}

@media screen and (min-width: 100px) and (max-width: 560px) {
  .searchInput {
    width: 100%;
  }
}

@media screen and (min-width: 100px) and (max-width: 390px) {
  .previewWrapper {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}
