.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
  padding: 0px 60px 60px 60px;
}

.contentWrapper {
  margin-top: 30px;
}

.title {
  margin-bottom: 24px;
  text-align: center;
}

.cover {
  height: 500px;
  object-fit: scale-down;
  width: 100%;
}

.imageDetails {
  color: #667085;
  font-size: 14px;
  text-align: center;
}

.sectionContent {
  margin-top: 20px;
}
