.wrapper {
  background-color: var(--app-background-color);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: calc(100vh - var(--footer-height));
}

.header {
  padding: 0px var(--app-padding-left-right);
}

.loginMessage {
  color: #CFCFCF;
  font-size: 48px;
  margin-bottom: auto;
  margin-top: auto;
  padding: 10px;
  text-align: center;
  white-space: pre-wrap;
}

.contentWrapper {
  column-gap: 18px;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-template-columns: none;
  padding-top: 10px;
  position: relative;
}

.eventInfoWrapper {
  padding-left: var(--app-padding-left-right);
  padding-bottom: 28px;
}

.eventInfoWrapperHidden {
  display: none;
}

.eventInfo {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 10px;
}

.carouselDots li button {
  background: var(--app-primary-color) !important;
  opacity: 1 !important;
}

.eventInfo img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 290px;
  margin-bottom: 12px;
  object-fit: contain;
  width: 100%;
}

.eventInfo .date,
.eventInfo .description {
  font-size: 16px;
  font-weight: 400;
}

.eventInfo .location {
  align-items: center;
  color: var(--app-primary-color);
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.eventInfo .location svg {
  margin-right: 10px;
}

.eventInfo h1 {
  font-weight: 700;
  font-size: 24px;
  margin: 6px 0px;
  text-align: left;
}

.eventInfo button {
  margin-top: 10px;
  width: 100%;
}

.resultCategories {
  width: 100%;
}

.mapWrapper {
  height: 100%;
  position: relative;
}

.showMapSmallScreenBtn {
  align-items: center;
  background-color: white;
  border: 1px solid var(--app-primary-color);
  border-radius: 20px;
  cursor: pointer;
  color: var(--app-primary-color);
  display: none;
  height: 40px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 8px 15px;
  width: 148px;
}

.showMapSmallScreenBtn svg {
  margin-right: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .header {
    padding: 0px 10px;
  }

  .contentWrapper {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    grid-template-columns: 1fr;
  }

  .eventInfoWrapper {
    padding-left: 10px;
    padding-bottom: 0px;
    width: 97vw;
  }

  .eventInfo {
    margin-top: 10px;
  }

  .showMapSmallScreenBtn {
    display: flex;
    position: sticky;
    bottom: 20px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    z-index: 100;
  }

  .resultCategories {
    width: 100vw;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  .header {
    padding: 0px 10px;
  }

  .loginMessage {
    font-size: 24px;
  }

  .contentWrapper {
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    grid-template-columns: 1fr;
  }

  .eventInfoWrapper {
    padding-left: 10px;
    padding-bottom: 0px;
    width: 97vw;
  }

  .eventInfo {
    margin-top: 10px;
  }

  .showMapSmallScreenBtn {
    display: flex;
    position: sticky;
    bottom: 20px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    z-index: 100;
  }

  .resultCategories {
    width: 100vw;
  }
}
