.wrapper {
  align-self: baseline;
  width: 100%;
}

.collectionTitle {
  align-items: center;
  display: flex;
}

.collectionTitle svg {
  height: 16px;
  margin-left: 7px;
  width: 16px;
}

.collection {
  column-gap: 20px;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, 330px);
  justify-content: center;
  row-gap: 20px;
}

.noListsMessage {
  opacity: 0.7;
  text-align: center;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .collection {
    grid-template-columns: repeat(auto-fill, 280px);
  }
}
