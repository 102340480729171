.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper h3 {
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  text-align: center !important;
}

.description {
  color: #667085;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.eventTypeOptionsWrapper {
  background: #F9F5FF;
  border-radius: 16px;
  display: flex;
  margin-bottom: 15px;
  padding: 4px 6px 4px 4px;
  width: fit-content;
}

.eventTypeOption {
  border-radius: 16px;
  color: #075CEB;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 8px 0px 8px;
}

.activeEventTypeOption {
  background: #075CEB;
  color: white;
}

.label {
  font-size: 16px;
}

.dateFieldsWrapper {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.semiPrivateExplanation,
.privateExplanation,
.publicExplanation {
  position: absolute;
  margin-left: 25px;
  font-size: 12px;
  color: #667085;
}

.privateExplanation {
  top: 30px;
}

.semiPrivateExplanation {
  top: 85px;
}

.publicExplanation {
  top: 155px;
}

.privateOption,
.publicOption {
  position: relative;
  margin-bottom: 30px;
}

.semiPrivateOption {
  position: relative;
  margin-bottom: 40px;
}

.validationError {
  color: #ff4d4f;
}

.deleteConfirmationText {
  text-align: center;
}

.selectedEventPhotoItem {
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  height: 66px;
  justify-content: space-between;
  margin-top: 7px;
  padding: 8px;
}

.selectedEventPhotoItem svg {
  cursor: pointer;
}

.shareCodeBlock {
  align-items: center;
  display: grid;
  grid-template-columns: 270px 50px;
  margin: 5px 0px 25px 0px;
  width: 300px;
}

.code {
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  max-height: 100px;
  padding: 10px 15px;
  overflow: auto;
  user-select: none;
  width: 270px;
}

.copyIcon {
  cursor: pointer;
}

.copyIcon:hover g path {
  stroke: var(--app-primary-color);
}

.btnArea {
  display: flex;
  margin-top: 40px;
}

.btnArea button {
  width: 100%;
}

.btnArea button:first-of-type {
  margin-right: 10px;
}

@media screen and (min-width: 100px) and (max-width: 630px) {
  .dateFieldsWrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 100px) and (max-width: 378px) {
  .privateOption,
  .publicOption {
    margin-bottom: 40px;
  }


.semiPrivateOption {
  margin-bottom: 55px;
}

  .privateExplanation {
    top: 100px;
  }
  
  .publicExplanation {
    top: 180px;
  }
}
