.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.modalTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px;
  text-align: center;
}

.description {
  color: #667085;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.inputLabel {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
}

.btnArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  width: 100%;
}
