@import './../../../app/styleVars.css';

.header {
  align-items: center;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 30px 0px;
}

.logo img {
  height: 50px;
  width: 250px;
}

.popoverMenu {
  display: none;
}

.menu {
  align-items: center;
  display: flex;
}

.menu :not(:last-child) {
  margin-right: 50px;
}

.menu a,
.menu div {
  color: var(--app-dark-color);
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-decoration: none;
}

.menu a.active {
  font-weight: 700;
}

.userMenuMobile {
  display: none;
}

.userMenu {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 20px 0px;
}

.userMenuItem {
  cursor: pointer;
  color: var(--app-dark-color);
  display: block;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.userMenuItem:hover {
  background: #F9F8FE;
  color: var(--app-dark-color);
}

.userMenu div {
  margin-top: 10px;
}

.userAvatar {
  background-color: var(--app-dark-color);
  cursor: pointer;
  margin-right: 0px !important;
  vertical-align: middle;
}

@media screen and (min-width: 100px) and (max-width: 1133px) {
  .logo img {
    height: 29px;
    position: relative;
    width: 140px;
    z-index: 5;
  }

  .menu {
    display: none;
  }

  .popoverMenu {
    display: block;
  }
  
  .popoverMenuIcon {
    cursor: pointer;
    /* margin-right: 20px; */
  }

  .userMenuPopover {
    display: none;
  }

  .userMenuMobile {
    display: block;
  }

  .userMenu {
    background: #FFFFFF;
    box-shadow: none;
    border-radius: none;
    padding: 0px;
  }

  .menuSmallScreen {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  .menuSmallScreen a,
  .menuSmallScreen div {
    color: var(--app-dark-color);
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    padding: 7px 10px;
    text-align: center;
    text-decoration: none;
  }
}
