.form {
  max-width: 850px;
  width: 100%;
}

.label {
  font-size: 16px;
}

.doubleField {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.avatarArea {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 110px 1fr;
}

.avatarWrapper {
  position: relative;
}

.avatarWrapper svg {
  bottom: 0px;
  cursor: pointer;
  right: 0px;
  position: absolute;
}

.avatarWrapper svg:hover path {
  stroke: var(--app-primary-color);
}

.userAvatar {
  margin-right: 0px !important;
  vertical-align: middle;
  user-select: none;
}

.defaultAvatar {
  background-color: var(--app-dark-color);
}

.uploadText {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.uploadDescription {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;  
}

.btnAreaWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.btnAreaWrapper button:first-of-type {
  margin-right: 10px;
}

.subscriptionCheckbox {
  background: rgba(0, 104, 255, 0.05);
  border-radius: 8px;
  border: 1px solid #0068FF;
  padding: 2px 16px;
  margin-top: 33px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .doubleField {
    grid-template-columns: 1fr;
  }

  .avatarArea {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .avatarWrapper {
    margin: 0 auto;
  }
}
