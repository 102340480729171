.tabs {
  padding: 0px 10px;
}

.tabs {
  padding-left: var(--app-padding-left-right);
}

.tabsSmallScreenWrapper {
  display: none;
}

.tabsWrapper {
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 10px;
}

.tab {
  align-items: center;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  display: flex;
  padding: 12px 17px;
  position: relative;
  margin-right: 5px;
}

.tab h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}

.tab.active {
  background: #FFFFFF ;
}

.tab.active h2 {
  color: var(--app-highlight-color);
}

.tab button {
  height: 30px;
  margin-left: 10px;
  padding: 0px 10px;
}

.removeTabIcon {
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: 3px;
  width: 15px;
  z-index: 1000;
}

.spinnerWrapper {
  display: flex;
  justify-content:center;
  margin-top: 30px;
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tabs {
    padding-left: 30px;
  }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .tabs {
    display: none;
  }

  .tabsSmallScreenWrapper {
    display: block;
  }

  .tabOption {
    color: black;
    display: grid;
    grid-template-columns: 1fr 45px;
    margin-bottom: 10px;
    padding: 0px 5px;
    overflow-x: auto;
  }

  .tabOption.active {
    cursor: pointer;
    color: gray
  }

  .newSearchSmallScreen {
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    overflow-y: auto;
  }

  .newSearchSmallScreen button:last-of-type {
    margin-right: 10px;
  }
}
